// ==========================================================================
// Nav
// ==========================================================================

let menu = document.getElementById('str-menu');
if( menu ){
    new Mmenu( "#str-menu", {

        "navbars": [
             {
                "position": "top",
                "content": [
                   "searchfield"
                ]
             },
             {
                "position": "bottom",
                "content": [
                   "<a class='fa fa-envelope' href='mailt:salesuk@str-subsea.com'></a>",
                   "<a class='fa fa-phone' href='tel:+4401493445400'></a>",
                   "<a class='fa fa-linkedin' href='https://www.linkedin.com/company/subsea-technology-&-rentals-ltd'></a>"
                ]
             }
          ],
       "extensions": [
           "position-right",
       ],
       "offCanvas": {
           "position": "right"
       },
       "setSelected": {
             "hover": true
          }
   });
}
