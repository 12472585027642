// Starting up Alpine
import Alpine from "alpinejs"
import focus from "@alpinejs/focus"
import ui from "@alpinejs/ui"

window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.plugin(ui)
Alpine.start();

// Stops JS errors to appear on the Console
import "console-polyfill/index";

// Require Mmenu
import "mmenu-js/dist/mmenu";
