import Swiper from "swiper/bundle";
// import Swiper styles
import "swiper/css/bundle";

var swiper = new Swiper(".CardBlock__Swiper", {
    loop: true,
    slidesPerView: 1.5,
    spaceBetween: 24,

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },

    breakpoints: {


        992: {
            slidesPerView: 3.5,
            spaceBetween: 24,
        },

        1200: {
            slidesPerView: 4.5,
            spaceBetween: 24,
        },


    }
});
